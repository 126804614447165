<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- Overview"/>

        <v-spacer></v-spacer>

        

      </v-toolbar> 



      <v-divider></v-divider>

        <template>
            <v-dialog v-model="showSlotDialog" v-if="selDetail != []">
                <v-card>
                    <v-card-title>
                        {{selTitle}}
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="slotHeaders"
                            :items="selDetail"
                            :items-per-page="100"
                            :footer-props="footerProps"
                            class="elevation-1"
                            :sort-by="['start_time']"
                            dense
                        >
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeSlotPreview">Cancel</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </template>
      <div class="content-wrap">

            <v-row justify="end" >
                <v-col cols="10" sm="8" md="6" >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>

           <v-row>
                
              <v-col cols="12" sm="12" md="12"  >
                    <v-data-table
                        :headers="headers"
                        :items="rosters"
                        :items-per-page="100"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :sort-by="['store_name']"
                        :sort-desc="[true]"
                        :search="search"

                        dense
                        >

                        <template
                            v-slot:item="{ item }"
                        >
                            <tr>
                                <td>{{ item.store_name }}</td>
                                <td>    
                                    <div v-for="schedule in item.schedules" :key="schedule.name">
                                        {{ schedule.name.length > 15 ? schedule.name.substr(0, 15) + "..." : schedule.name }}
                                    </div>
                                </td>
                                <td v-for="slot in headersDate" :key="slot.slot_date" v-bind:class="isToday(slot.slot_date)" >
                                    <div v-for="schedule in item.schedules" :key="schedule.name" @click="showSlotPreview(schedule.slots[slot.slot_date].data, item.store_name + ' ' + slot.slot_date)">
                                        <span class="blue--text"><b>{{ schedule.slots[slot.slot_date].planing }}</b></span> /
                                        <span class="green--text"><b>{{ schedule.slots[slot.slot_date].online }}</b></span> /
                                        <span class="red--text"><b>{{ schedule.slots[slot.slot_date].offline }}</b></span>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>

              </v-col>
          </v-row>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        selSchedule : null,
        selDetail : [],
        selTitle : null,
        showSlotDialog : false,
        rosters : [
        ],
        headersDate : [],
        search : "",
        payment_records : [],
        footerProps: {'items-per-page-options': [50, 100, 500]},
        today : null,
        headers: [
            
        ],
        slotHeaders : [
            { text: 'Start', value: 'slot_start', groupable: false},
            { text: 'End', value: 'slot_end', groupable: false },
            { text: 'Employee No', value: 'employee.employee_no', width: 120 },
            { text: 'Name', value: 'employee.name_th', width: 120 },
            { text: 'Tel', value: 'employee.phone' },
            { text: 'Status', value: 'roster_status' }
        ],
        menu: {
            start_date: false,
            end_date: false,
            paid_date: false
        },
        newItem: {
            name: null,
            start_date: null,
            end_date: null,
            paid_date: null
        },
        validate_rules : {
            required: v => !!v || 'This field is required',
            filesize:  v => !v || v.size < 2048*1024 || 'Updload size should be less than 2 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                    return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                    return 'This field is required'
                }
            }
        },
    }),
    components: {
        pageHeading
    },
    methods: {
         ...mapActions(['genCan', 'getOverallRosteringByStoreSchedule' ]),
        isToday(inp_date) {
            const moment = require('moment-timezone');

            let ret_class = [];
            if (inp_date == moment().tz('Asia/Bangkok').format("YYYY-MM-DD"))
            {
                ret_class.push('amber');
                ret_class.push('lighten-4');
            }
            return ret_class;
        },

        showSlotPreview(detail, title) {
            this.showSlotDialog = true;
            console.log(detail);
            this.selDetail = detail;
            this.selTitle = title;
        },
        closeSlotPreview() {
            this.showSlotDialog = false;
            this.selDetail = [];
            this.selTitle = "";
        },

        async reloadData() {
            const lodash = require('lodash');
            const moment = require('moment-timezone');

            this.today = moment().tz("Asia/Bangkok");

            this.headers = [
                { text: 'Store', value: 'store_name', width: 200 },
                { text: 'Type', value: 'schedule_name_pre', width: 150 },

            ]
            this.headersDate = [];

            let start_date = moment().tz("Asia/Bangkok").subtract(1, "days").format("YYYY-MM-DD");
            let end_date = moment().tz("Asia/Bangkok").add(4, "days").format("YYYY-MM-DD");

            for (var m = moment(start_date); m.isSameOrBefore(end_date); m.add(1, 'days')) {
                this.headers.push({ text: m.format('D MMM'), value: m.format("YYYY-MM-DD"), groupable: false, width: 100});
                this.headersDate.push({slot_date : m.format("YYYY-MM-DD")})
            }

            this.rosters = await this.getOverallRosteringByStoreSchedule();
        },

    },
    async beforeMount() {
        this.genCan();
        this.reloadData();
    },

    computed: {
        ...mapGetters(['canDo','allCategories'])
    },
    mounted() {
    }
};
</script>